<template>
  <v-card flat class="rounded-lg mt-4">
    <v-card-title>
      <span class="text-color-title body-1 font-weight-bold">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn small depressed dark color="black" @click="deleteComponent()">
        Delete
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-textarea
          v-model="form.body"
          class="px-4 rounded-lg"
          placeholder="Add Quote"
          :rules="MIXINS_REQUIRED_RULES"
          dense
          filled
          rows="5"
          auto-grow
        ></v-textarea>
        <v-text-field
          v-model="form.reference"
          :rules="MIXINS_REQUIRED_RULES"
          placeholder="Add Reference"
          dense
          filled
          class="px-4 rounded-lg"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { BUILDER_COMPONENT_EVENT_BUS } from "../../services/builderComponentEventBus";

export default {
  data: () => ({
    title: "Quote",
    valid: false
  }),
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    deleteComponent() {
      let key = this.$attrs.componentKey;

      BUILDER_COMPONENT_EVENT_BUS.$emit("deleteComponent", {
        key,
        partId: this.itemId
      });
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
